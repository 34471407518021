@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font: 300 llpx/1, 4 'Helvetica Neue', 'sans-serif';
  color: #444;
  display: block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Chrome/Safari Support */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #403f3f;
}

::-webkit-scrollbar-thumb {
  background: #5a5a5a;
  border-radius: 100vw;
}

::-webkit-scrollbar-thumb:hover {
  background: #1e1e1e;
}

/* Firefox support */
* {
  scrollbar-color: #5a5a5a #403f3f;
  scrollbar-width: thin;
}
