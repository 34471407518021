.home-page {
  h1 {
    color: #fff;
    font-size: 53px;
    font-family: 'Coolvetica';
    font-weight: 400;

    &::before {
      color: #b96fc8;
      font-size: 18px;
      position: relative;
      opacity: 0.6;
    }

    &::after {
      color: #b96fc8;
      font-size: 18px;
      position: relative;
      animation: fadeIn 1s 1.7s backwards;
      opacity: 0.6;
    }
  }

  h2 {
    color: #575757;
    font-family: sans-serif;
    letter-spacing: 3px;
    animation: fadeIn 1s 1.8s backwards;
  }
}
