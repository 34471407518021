.box {
  opacity: 0;
  z-index: 1;
  transform-style: preserve-3d;
  animation: fadeIn 1s forwards;
  animation-delay: 1s;
}

.projects-page,
.contact-page,
.about-page {
  .text-zone {
    h1 {
      font-size: 53px;
      font-family: 'Coolvetica';
      color: #fff;
      font-weight: 400;
      letter-spacing: 3px;

      &::before {
        font-size: 18px;
        opacity: 0.6;
      }

      &::after {
        font-size: 18px;
        opacity: 0.6;
      }
    }

    p {
      font-style: 13px;
      color: #fff;
      font-family: sans-serif;
      font-weight: 300;
      min-width: fit-content;
      animation: pulse 1s;
      font-size: 15px;

      &:nth-of-type(1) {
        animation-delay: 1.1s;
      }

      &:nth-of-type(2) {
        animation-delay: 1.2s;
      }

      &:nth-of-type(3) {
        animation-delay: 1.3s;
      }
    }
  }

  .text-animate-hover {
    &:hover {
      color: #b96fc8;
    }
  }
}
